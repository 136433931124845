import { createReducer, on } from '@ngrx/store';
import * as AppConfigurationActions from './app-configurations.actions';

export const FeatureKey = 'appConfigurations';

export interface AppConfigurationsState {
  usedInventorySignup: boolean;
  navCollapsed: boolean;
  theme: string;
}

export const initialState: AppConfigurationsState = {
  usedInventorySignup: false,
  navCollapsed: false,
  theme: 'buyer',
};

export const appConfigurationsReducer = createReducer(
  initialState,
  on(AppConfigurationActions.initialize, (state, { navCollapsed }) => {
    return { ...state, navCollapsed };
  }),
  on(AppConfigurationActions.sellerWaitlistSignupSuccess, (state) => {
    return { ...state, usedInventorySignup: true };
  }),
  on(AppConfigurationActions.setNavCollapsed, (state, { navCollapsed }) => {
    return { ...state, navCollapsed };
  }),
  on(AppConfigurationActions.setTheme, (state, { theme }) => ({
    ...state,
    theme,
  })),
);
