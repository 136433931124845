import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import * as AppConfigurationActions from './app-configurations.actions';
import { filter, map, switchMap, take, tap } from 'rxjs';
import { selectNavCollapsed } from './app-configurations.selectors';
import { Store } from '@ngrx/store';
import { StorageService } from '@chassis/shared/services/storage';
import { selectUrl, SharedActions } from '@chassis/shared/actions';
import { routerNavigationAction } from '@ngrx/router-store';
import { NavigationService } from '@chassis/shared/navigation';
import { NavigationEnd, Router } from '@angular/router';

const SELLER_WAITLIST_KEY = 'used-inventory-waitlist';
const NAV_COLLAPSED = 'nav-collapsed';

@Injectable()
export class AppConfigurationsEffects {
  readonly actions$ = inject(Actions);
  readonly store = inject(Store);
  readonly router = inject(Router);
  readonly navigationService = inject(NavigationService);
  readonly localStorageService = inject(StorageService);

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.initPostAuth),
      map(() => {
        const navCollapsed =
          this.localStorageService.getItem(NAV_COLLAPSED) === 'true';
        return AppConfigurationActions.initialize({ navCollapsed });
      }),
    ),
  );

  persistRoute$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerNavigationAction),
        switchMap(() =>
          this.store.select(selectUrl).pipe(
            switchMap((url) =>
              this.router.events.pipe(
                filter((event) => event instanceof NavigationEnd),
                take(1),
                map(() => url),
              ),
            ),
          ),
        ),
        tap((url) => this.navigationService.persistRoute(url)),
      ),
    { dispatch: false },
  );

  toggleNavCollapsed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppConfigurationActions.toggleNavCollapsed),
      concatLatestFrom(() => this.store.select(selectNavCollapsed)),
      map(([_, oldNavCollapsed]) => {
        const navCollapsed = !oldNavCollapsed;
        this.localStorageService.setItem(
          NAV_COLLAPSED,
          navCollapsed.toString(),
        );
        return AppConfigurationActions.setNavCollapsed({ navCollapsed });
      }),
    ),
  );

  waitlistSignUp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppConfigurationActions.sellerWaitlistSignup),
      map(() => {
        this.localStorageService.setItem(SELLER_WAITLIST_KEY, 'YES');
        return AppConfigurationActions.sellerWaitlistSignupSuccess();
      }),
    ),
  );
}
