import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppConfigurationsState, FeatureKey } from './app-configurations.store';

export const selectAppConfigurationsState =
  createFeatureSelector<AppConfigurationsState>(FeatureKey);

export const selectUsedInventorySignup = createSelector(
  selectAppConfigurationsState,
  (state) => state.usedInventorySignup,
);

export const selectNavCollapsed = createSelector(
  selectAppConfigurationsState,
  (state) => state.navCollapsed,
);

export const selectAppTheme = createSelector(
  selectAppConfigurationsState,
  (state) => state.theme,
);
