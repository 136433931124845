import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import {
  appConfigurationsReducer,
  FeatureKey,
} from './app-configurations.store';
import { EffectsModule } from '@ngrx/effects';
import { AppConfigurationsEffects } from './app-configurations.effects';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(FeatureKey, appConfigurationsReducer),
    EffectsModule.forFeature([AppConfigurationsEffects]),
  ],
  providers: [],
})
export class AppStateModule {}
